import AuthComponent from "../components/AuthComponent";
import Scroll from "../components/Scroll";
import Header from "../components/Header";
import HostPage from "../components/HostPage";
import GenderSelPage from "../components/GenderSelPage";
import ImagePage from "../components/ImagePage";
import ParametersPage from "../components/ParametersPage";
import ResultPage from "../components/ResultPage";
import AboutPage from "../components/AboutPage";
import Footer from "../components/Footer";
import { deleteNumberTries } from "../numberTries";

import { fetchUserAttributes, deleteUser } from "@aws-amplify/auth";
import { post } from "aws-amplify/api";
import React, { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";

const Home = () => {
  let [gender, setGender] = useState("woman");
  let [isNude, setIsNude] = useState("underwear");
  let [isPhoto, setIsPhoto] = useState("photo");
  let [breastSize, setBreastSize] = useState("auto");
  let [bodySize, setBodySize] = useState("auto");
  let [isHairy, setIsHairy] = useState("auto");
  let [age, setAge] = useState("auto");
  let [power, setPower] = useState("light");
  let [outfit, setOutfit] = useState("sexy underwear");
  let [source, setSource] = useState("");
  let [isLoaded, setIsLoaded] = useState(false);
  let [isAuth, setIsAuth] = useState(false);
  let [userId, setUserId] = useState("");
  let [doAuth, setDoAuth] = useState(false);
  let [inImgSrc, setInImgSrc] = useState("");
  let [hasDrawn, setHasDrawn] = useState(false);
  let [doSub, setDoSub] = useState(false);
  let [displayPopUp, setDisplayPopUp] = useState(true);
  let [subscription, setSubscription] = useState("");
  let [hasReadSub, setHasReadSub] = useState(false);
  let [checkSub, setCheckSub] = useState(false);

  const retrieveSub = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      const restOperation = post({
        apiName: "undressStripeApi", //TODO env var
        path: "/checksub",
        options: {
          body: {
            userEmail: userAttributes.email,
            variable: "subscription",
          },
        },
      });
      const { body } = await restOperation.response;
      return body.json();
    } catch (e) {
      console.error("POST call failed: ", e);
      alert(
        "Something wrong happened: " +
          e +
          "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await retrieveSub();
        setSubscription(response.subscription);
        setHasReadSub(true);
      } catch (error) {
        console.log(error);
        console.log("An error occured getting your subscription");
      }
    };
    isAuth && fetchData();
    !isAuth && setHasReadSub(false);
    !isAuth && setSubscription("");
  }, [isAuth, checkSub]);

  useEffect(() => {
    // getting value of "seenPopUp" key from localStorage
    let returningUser = localStorage.getItem("seenPopUp");
    // if it's not there, for a new user, it will be null
    // if it's there it will be boolean true
    // setting the opposite to state, false for returning user, true for a new user
    setDisplayPopUp(!returningUser);
  }, []);

  const closePopUp = () => {
    // setting key "seenPopUp" with value true into localStorage
    localStorage.setItem("seenPopUp", true);
    // setting state to false to not display pop-up
    setDisplayPopUp(false);
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser();
      deleteNumberTries(userId);
    } catch (error) {
      console.log(error);
    }
  };

  // write some style to pass into modal
  const style = {
    fontFamily: "Montserrat",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    height: 300,
    border: "10px solid hotpink",
    boxShadow: 24,
    padding: 5,
    textAlign: "center",
    background: "#de4899",
  };

  return (
    <div className="App">
      {doAuth && (
        <div className="auth">
          <AuthComponent></AuthComponent>
          <button
            className="button_back"
            onClick={() => {
              setDoAuth(false);
              setDoSub(false);
            }}
          >
            Get back to website
          </button>
        </div>
      )}
      {displayPopUp && (
        <Modal
          open={true}
          // once pop-up will close "closePopUp" function will be executed
          onClose={closePopUp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* what user will see in the modal is defined below */}
            <h1>Are you 18+ ?</h1>
            <br />
            <p>
              By clicking Yes, you confirm that you are over 18 years old and
              agree to the Terms of Service and Privacy Policy.
            </p>
            <button
              onClick={closePopUp}
              style={{ width: "50px", textAlign: "center" }}
            >
              Yes
            </button>
          </Box>
        </Modal>
      )}
      <Scroll></Scroll>
      <Header
        onSignIn={(bool) => setDoAuth(bool)}
        isAuth={isAuth}
        onAuth={(bool, id) => {
          setIsAuth(bool);
          setUserId(id);
        }}
        doSub={doSub}
        setDoSub={setDoSub}
        setDoAuth={setDoAuth}
        onDeleteAccount={handleDeleteUser}
      ></Header>
      <HostPage onClick={(gender) => setGender(gender)}></HostPage>
      {/* <GenderSelPage onClick={(gender) => setGender(gender)}></GenderSelPage> */}
      <ImagePage
        onLoad={(url, source) => {
          setIsLoaded(true);
          setInImgSrc(url);
          setSource(source);
        }}
        inImgSrc={inImgSrc}
        onDraw={(bool) => setHasDrawn(bool)}
      ></ImagePage>
      {isLoaded && (
        <ParametersPage
          gender={gender}
          isNude={isNude}
          isPhoto={isPhoto}
          breastSize={breastSize}
          bodySize={bodySize}
          isHairy={isHairy}
          age={age}
          power={power}
          outfit={outfit}
          subscription={subscription}
          onClickGender={(e) => setGender(e.currentTarget.value)}
          onClickIsNude={(e) => {
            setIsNude(e.currentTarget.value);
            // if (
            //   e.currentTarget.value === "underwear" ||
            //   e.currentTarget.value === "nude"
            // ) {
            //   setIsNude(e.currentTarget.value);
            // } else {
            //   if (subscription !== "Standard") setIsNude(e.currentTarget.value);
            //   else
            //     alert(
            //       "Only for Pro and Expert Members. You have access to 'underwear' and 'nude' options"
            //     );
            // }
          }}
          onClickIsPhoto={(e) => {
            setIsPhoto(e.currentTarget.value);
          }}
          onSelectOutfit={(e) => {
            if (subscription === "Pro" || subscription === "Expert")
              setOutfit(e.target.value);
            else {
              alert("Only for Pro and Expert Members");
              setOutfit("sexy underwear");
            }
          }}
          onClickBreastSize={(e) => {
            if (subscription === "Pro" || subscription === "Expert")
              setBreastSize(e.currentTarget.value);
            else {
              alert("Only for Pro and Expert Members");
              setBreastSize("auto");
            }
          }}
          onClickBodySize={(e) => {
            if (subscription === "Pro" || subscription === "Expert")
              setBodySize(e.currentTarget.value);
            else {
              alert("Only for Pro and Expert Members");
              setBodySize("auto");
            }
          }}
          onClickIsHairy={(e) => {
            if (subscription === "Pro" || subscription === "Expert")
              setIsHairy(e.currentTarget.value);
            else {
              alert("Only for Pro and Expert Members");
              setIsHairy("auto");
            }
          }}
          onClickAge={(e) => {
            if (subscription === "Pro" || subscription === "Expert")
              setAge(e.currentTarget.value);
            else {
              alert("Only for Pro and Expert Members");
              setAge("auto");
            }
          }}
          onClickPower={(e) => {
            setPower(e.currentTarget.value);
          }}
          onClickPrompt={() => {
            if (subscription !== "Expert") {
              alert("Only for Expert Members");
            }
          }}
        ></ParametersPage>
      )}
      {isLoaded && (
        <ResultPage
          isLoaded={isLoaded}
          isAuth={isAuth}
          inImgSrc={inImgSrc}
          hasDrawn={hasDrawn}
          gender={gender}
          isNude={isNude}
          outfit={outfit}
          isPhoto={isPhoto}
          breastSize={breastSize}
          bodySize={bodySize}
          isHairy={isHairy}
          age={age}
          power={power}
          source={source}
          onNoMoreTry={() => setDoSub(true)}
        ></ResultPage>
      )}
      <AboutPage></AboutPage>
      <Footer></Footer>
    </div>
  );
};

export default Home;
