import React, { useState } from "react";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ParametersPage = ({
  gender,
  isNude,
  isPhoto,
  breastSize,
  bodySize,
  isHairy,
  age,
  power,
  outfit,
  subscription,
  onClickGender,
  onClickIsNude,
  onClickIsPhoto,
  onSelectOutfit,
  onClickBreastSize,
  onClickBodySize,
  onClickIsHairy,
  onClickAge,
  onClickPower,
  onClickPrompt,
}) => {
  let [morePro, setMorePro] = useState(true);
  let [moreExpert, setMoreExpert] = useState(true);

  return (
    <div className="parameters" id="parameters">
      <h1 className="step">
        <br />
        <br /> <br />
        <br />
        <u>Step 3: Set your preferences</u>
        <br />
        <br />
        <br />
      </h1>
      <h4>Standard Parameters</h4>
      <br />

      <div className="filter-switch">
        <input
          type="radio"
          name="gender"
          id="womanRadio"
          value="woman"
          checked={gender === "woman"}
          onChange={(e) => onClickGender(e)}
        />
        <label htmlFor="womanRadio" className="option">
          Undress ♀️
        </label>
        <input
          type="radio"
          name="gender"
          id="manRadio"
          value="man"
          checked={gender === "man"}
          onChange={(e) => onClickGender(e)}
        />
        <label htmlFor="manRadio" className="option">
          Undress ♂️
        </label>
        <span className="bg2"></span>
      </div>
      <div className="filter-switch">
        <input
          type="radio"
          name="isPhoto"
          id="photoRadio"
          value="photo"
          checked={isPhoto === "photo"}
          onChange={(e) => onClickIsPhoto(e)}
        />
        <label htmlFor="photoRadio" className="option">
          Photo
        </label>

        <input
          type="radio"
          name="isPhoto"
          id="mangaRadio"
          value="manga"
          checked={isPhoto === "manga"}
          onChange={(e) => onClickIsPhoto(e)}
        />
        <label htmlFor="mangaRadio" className="option">
          Manga
        </label>
        <span className="bg2"></span>
      </div>
      <div className="filter-switch">
        <input
          type="radio"
          name="isNude"
          id="underwearRadio"
          value="underwear"
          checked={isNude === "underwear"}
          onChange={(e) => onClickIsNude(e)}
        />
        <label htmlFor="underwearRadio" className="option">
          Underwear
        </label>
        <input
          type="radio"
          name="isNude"
          id="nudeRadio"
          value="nude"
          checked={isNude === "nude"}
          onChange={(e) => onClickIsNude(e)}
        />
        <label htmlFor="nudeRadio" className="option">
          Nude
        </label>
        <span className="bg2"></span>
      </div>
      <p className="infoText">Choose the type of underwear in Pro Parameters</p>

      <div className="filter-switch">
        <input
          type="radio"
          name="power"
          id="lightRadio"
          value="light"
          checked={power === "light"}
          onChange={(e) => onClickPower(e)}
        />
        <label htmlFor="lightRadio" className="option">
          Classic
        </label>
        <input
          type="radio"
          name="power"
          id="strongRadio"
          value="strong"
          checked={power === "strong"}
          onChange={(e) => onClickPower(e)}
        />
        <label htmlFor="strongRadio" className="option">
          Strong
        </label>
        <span className="bg2"></span>
      </div>
      <p className="infoText">
        "Strong" is only recommended for very dark clothes
      </p>

      <br />

      <br />
      <div
        className="params"
        style={{ textAlign: "center" }}
        onClick={() => {
          setMorePro(!morePro);
        }}
      >
        <h4>Pro Parameters</h4>
        {!morePro && <box-icon name="chevron-down"></box-icon>}
        {morePro && <box-icon name="chevron-up"></box-icon>}
      </div>
      {morePro && (
        <>
          {isNude === "underwear" && (
            <div>
              <label>Choose the type of Underwear</label>
              <Box sx={{ width: 300, marginInlineStart: "auto",marginInlineEnd: "auto" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={outfit}
                    label="Outfit"
                    onChange={(e) => onSelectOutfit(e)}
                  >
                    <MenuItem value="sexy underwear">Underwear</MenuItem>
                    {gender === "woman" && (
                      <MenuItem value="lingerie">Lingerie</MenuItem>
                    )}
                    <MenuItem value="realistic bondage outfit">
                      Bondage
                    </MenuItem>
                    <MenuItem value="sexy latex suit">Latex</MenuItem>
                    {gender === "woman" && (
                      <MenuItem value="sexy monokini wetsuit">
                        Monokini
                      </MenuItem>
                    )}
                    {gender === "woman" && (
                      <MenuItem value="sexy bikini">Bikini</MenuItem>
                    )}
                    {gender === "woman" && (
                      <MenuItem value="wedding dress">Wedding</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </div>
          )}

          {/* elegant dress, wedding dress, bathrobe, sexy nurse uniform*/}
          {gender === "woman" && (
            <div className="filter-switch">
              <input
                type="radio"
                name="breastSize"
                id="smallBreast"
                value="small"
                checked={breastSize === "small"}
                onChange={(e) => onClickBreastSize(e)}
              />
              <label htmlFor="smallBreast" className="option">
                Small Tits
              </label>
              <input
                type="radio"
                name="breastSize"
                id="autoBreast"
                value="auto"
                checked={breastSize === "auto"}
                onChange={(e) => onClickBreastSize(e)}
              />
              <label htmlFor="autoBreast" className="option">
                Auto
              </label>
              <input
                type="radio"
                name="breastSize"
                id="bigBreast"
                value="big"
                checked={breastSize === "big"}
                onChange={(e) => onClickBreastSize(e)}
              />
              <label htmlFor="bigBreast" className="option">
                Big Tits
              </label>
              <span className="bg3"></span>
            </div>
          )}
          <div className="filter-switch">
            <input
              type="radio"
              name="bodySize"
              id="skinnyBody"
              value="skinny"
              checked={bodySize === "skinny"}
              onChange={(e) => onClickBodySize(e)}
            />
            <label htmlFor="skinnyBody" className="option">
              Skinny
            </label>
            <input
              type="radio"
              name="bodySize"
              id="autoBody"
              value="auto"
              checked={bodySize === "auto"}
              onChange={(e) => onClickBodySize(e)}
            />
            <label htmlFor="autoBody" className="option">
              Auto
            </label>
            <input
              type="radio"
              name="bodySize"
              id="fatBody"
              value="fat"
              checked={bodySize === "fat"}
              onChange={(e) => onClickBodySize(e)}
            />
            <label htmlFor="fatBody" className="option">
              Curvy
            </label>
            <span className="bg3"></span>
          </div>
          <div className="filter-switch">
            <input
              type="radio"
              name="age"
              id="youngAge"
              value="young"
              checked={age === "young"}
              onChange={(e) => onClickAge(e)}
            />
            <label htmlFor="youngAge" className="option">
              Younger
            </label>
            <input
              type="radio"
              name="age"
              id="autoAge"
              value="auto"
              checked={age === "auto"}
              onChange={(e) => onClickAge(e)}
            />
            <label htmlFor="autoAge" className="option">
              Auto
            </label>
            <input
              type="radio"
              name="age"
              id="oldAge"
              value="old"
              checked={age === "old"}
              onChange={(e) => onClickAge(e)}
            />
            <label htmlFor="oldAge" className="option">
              Older
            </label>
            <span className="bg3"></span>
          </div>
          {isNude === "nude" && (
            <div className="filter-switch">
              <input
                type="radio"
                name="isHairy"
                id="autoHairy"
                value="auto"
                checked={isHairy === "auto"}
                onChange={(e) => onClickIsHairy(e)}
              />
              <label htmlFor="autoHairy" className="option">
                Auto
              </label>
              <input
                type="radio"
                name="isHairy"
                id="yesHairy"
                value="true"
                checked={isHairy === "true"}
                onChange={(e) => onClickIsHairy(e)}
              />
              <label htmlFor="yesHairy" className="option">
                Hairy
              </label>
              <span className="bg2"></span>
            </div>
          )}
        </>
      )}

      <br />
      <div
        className="params"
        style={{ textAlign: "center" }}
        onClick={() => {
          setMoreExpert(!moreExpert);
        }}
      >
        <h4>Expert Parameters</h4>
        {!moreExpert && <box-icon name="chevron-down"></box-icon>}
        {moreExpert && <box-icon name="chevron-up"></box-icon>}
      </div>
      {moreExpert && (
        <div style={{ textAlign: "center" }} onClick={() => onClickPrompt()}>
          <h3 className="subtitle" style={{ color: "green" }}>
            <b>Total FREEDOM: </b>
          </h3>
          <br />
          <p>
            Get anything you want ! <br /> Just write it in the prompt. <br />
            Try to be very descriptive and use "," as separators
          </p>
          <br />
          <input
            onClick={() => onClickPrompt()}
            type="text"
            id="expertPrompt"
            maxLength="70"
            size="30"
            disabled={subscription !== "Expert"}
          ></input>
        </div>
      )}
    </div>
  );
};

export default ParametersPage;
