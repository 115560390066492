import { loadStripe } from "@stripe/stripe-js";
import { fetchUserAttributes } from "@aws-amplify/auth";
import { post } from "aws-amplify/api";

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthComponent from "./AuthComponent";

export default function Subscribe({ isAuth, doSub, setDoSub, setDoAuth }) {
  let [userId, setUserId] = useState("");
  let [hasRead, setHasRead] = useState(false);
  // useEffect(() => {
  //   // checkSub();
  // }, [isSub, isAuth]);

  //   const subscribePage = async (e) => {
  //     // TODO: delete
  //     const userAttributes = await fetchUserAttributes();
  //     const stripe = await loadStripe(
  //       "pk_test_51PXmy9B6xInYzrcG6L3NCxxa3p9KwTfa4dnDaPar3DrNXwWXodS3PxLI9UhBu2IifVRD9ZW4nMyogke9mLebpUdp00oprSqvaZ" // process.env.REACT_APP_ENV_STRIPE_TEST_API_KEY // TODO Env vars
  //     );
  //     const { error } = await stripe.redirectToCheckout({
  //       lineItems: [
  //         {
  //           price: "price_1PYpObB6xInYzrcGOXSldmcV", //process.env.REACT_APP_ENV_STRIPE_PRICE_KEY, // TODO Env vars
  //           quantity: 1,
  //         },
  //       ],
  //       mode: "subscription",
  //       successUrl: "http://localhost:3000/", // TODO: put ailovenudes.com ?
  //       cancelUrl: "http://localhost:3000/cancelSubscription",
  //       customerEmail: userAttributes.email,
  //     });
  //   };

  //   const checkSub = async () => {
  //     if (!isAuth) {
  //       const finalDict = { value: false };
  //       if (isSub.value !== finalDict.value) onIsSub(finalDict);
  //       return;
  //     }
  //     try {
  //       const userAttributes = await fetchUserAttributes();
  //       const restOperation = post({
  //         apiName: "undressStripeApi", //process.env.REACT_APP_ENV_STRIPE_API_NAME, // TODO Env vars
  //         path: "/checksub",
  //         options: {
  //           body: {
  //             email: userAttributes.email,
  //           },
  //         },
  //       });
  //       const { body } = await restOperation.response;
  //       const response = await body.json();
  //       const finalDict = {
  //         value: response["isSub"],
  //         product: response["product"],
  //       };
  //       if (
  //         !Object.keys(isSub).includes("product") ||
  //         isSub.value !== finalDict.value ||
  //         isSub.product !== finalDict.product
  //       )
  //         onIsSub(finalDict);
  //     } catch (e) {
  //       console.error("POST call failed: ", e);
  //       alert(
  //         "Something wrong happened: " +
  //           e +
  //           "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
  //       );
  //     }
  //   };

  const retrieveId = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      const restOperation = post({
        apiName: "undressStripeApi", //TODO env var
        path: "/checksub",
        options: {
          body: {
            userEmail: userAttributes.email,
            variable: "id",
          },
        },
      });
      const { body } = await restOperation.response;
      return body.json();
    } catch (e) {
      console.error("POST call failed: ", e);
      alert(
        "Something wrong happened: " +
          e +
          "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // try {
      //   userAttributes = await fetchUserAttributes();
      //   setUserEmail(userAttributes.email);
      //   if (userAttributes.email === "") console.log("Error: Email is empty");
      // } catch (error) {
      //   console.log(error);
      //   console.log("You need to sign in first");
      // }
      try {
        setUserId("");
        const response = await retrieveId();
        setUserId(response.userId);
        setHasRead(true);
      } catch (error) {
        console.log(error);
        console.log("An error occured getting your email");
      }
    };
    isAuth && fetchData();
    !isAuth && setHasRead(false);
  }, [isAuth]);

  useEffect(() => {
    doSub &&
      isAuth &&
      hasRead &&
      userId !== "" &&
      window.open(
        "https://ailovecreation.com/subscriptions?n=" + userId,
        "_blank"
      ) &&
      setDoSub(false);

    doSub && !isAuth && setDoAuth(true);
  }, [userId, doSub]);

  return (
    <>
      {/* {isAuth && isSub["value"] ? (
        <a href={"https://billing.stripe.com/p/login/test_cN2188564dn4fza144"}>
          Upgrade
        </a>
      ) : ( */}
      {/* {doSub && <Navigate to="/subscriptions" />} */}
      {/* <a href={"/subscriptions"}>Subscribe</a> */}

      {/* <a
        className="button"
        target="_blank"
        rel="noopener noreferrer"
        // href={"https://ailovecreation.com/subscriptions?n=" + userId}
        // onClick={() => {
        //   subscribe("OneTimeCard");
        // }}
        href={"https://ailovecreation.com/subscriptions?n=" + "0"}
        onClick={() => {
          console.log("sub");
        }}
      >
        Subscribe
      </a> */}
      <button
        onClick={() => {
          isAuth &&
            alert(
              "A new page AiLoveCreation will open. If not please allow pop-up to open in your navigator."
            );
          setDoSub(true);
        }}
      >
        Subscribe
      </button>

      {/* )} */}
    </>
  );
}
