import React from "react";

const AboutPage = () => {
  return (
    <section className="about" id="about">
      <div className="container">
        <div className="content">
          <h3>
            About us <box-icon name="info-circle"></box-icon>
          </h3>
          <p>
            {" "}
            We are a team of artists, AI engineers and developers, dedicated to
            create the most realistic undress app on the market, working not
            only for women, but for men too, and that for all types of bodies.
            <br />
            <br /> Undress anyone you want, upload your own photo, or a cartoon
            (with option "Manga").
            <br />
            <br /> It's all about freedom ...
            <br />
            <br /> We are constantly improving the algorithm, so stay tuned !
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
