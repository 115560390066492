import React, { useState, useRef } from "react";
import SignButton from "./SignButton";
import Subscribe from "./Subscribe";
import Unsubscribe from "./Unsubscribe";
import "boxicons";

const Header = ({
  onSignIn,
  isAuth,
  onAuth,
  doSub,
  setDoSub,
  setDoAuth,
  onDeleteAccount,
}) => {
  let [openHamb, setOpenHamb] = useState(false);
  const hambRef = useRef("menu");
  const closeHamb = (e) => {
    if (openHamb && !hambRef.current?.contains(e.target)) {
      setOpenHamb(false);
    }
  };

  const toggleHamburger = () => {
    document.getElementById("hambIcon").classList.toggle("bx-x");
    setOpenHamb(!openHamb);
  };

  document.addEventListener("mousedown", closeHamb);

  return (
    <>
      <header id="header">
        <div className="container">
          <a href="#home" className="logo">
            <img src="img/ailovenudes_logo.png" alt="website logo" />
          </a>

          <div className="right">
            <nav className="nav-desktop">
              <a href="#home" className="">
                Home
              </a>
              {/* <a href="#services" className="">
                Examples
              </a> */}
              <a href="#generate" className="">
                Generate
              </a>
              <Subscribe
                isAuth={isAuth}
                doSub={doSub}
                setDoSub={setDoSub}
                setDoAuth={setDoAuth}
              ></Subscribe>
              <a href="#about" className="">
                About us
              </a>
              <SignButton
                onSignIn={onSignIn}
                isAuth={isAuth}
                onAuth={onAuth}
              ></SignButton>
            </nav>

            <div className="hamb" ref={hambRef}>
              <box-icon
                name="menu"
                id="hambIcon"
                onClick={toggleHamburger}
              ></box-icon>
              {openHamb && (
                <nav className="nav-mobile" id="nav-mobile">
                  <a href="#home" className="">
                    Home
                  </a>
                  <a href="#generate" className="">
                    Generate
                  </a>
                  <Subscribe
                    isAuth={isAuth}
                    doSub={doSub}
                    setDoSub={setDoSub}
                    setDoAuth={setDoAuth}
                  ></Subscribe>
                  <a href="#about" className="">
                    About us
                  </a>
                  <SignButton
                    onSignIn={onSignIn}
                    isAuth={isAuth}
                    onAuth={onAuth}
                  ></SignButton>
                </nav>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
