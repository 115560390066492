import React, { useEffect, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import LoadingComponent from "./LoadingComponent";
import { post } from "aws-amplify/api";
import { fetchUserAttributes } from "@aws-amplify/auth";

const ResultPage = ({
  isLoaded,
  isAuth,
  inImgSrc,
  hasDrawn,
  gender,
  isNude,
  outfit,
  isPhoto,
  breastSize,
  bodySize,
  isHairy,
  age,
  power,
  source,
  onNoMoreTry,
}) => {
  let [isSubmitted, setIsSubmitted] = useState(false);
  let [isAuthRequired, setIsAuthRequired] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [isReady, setIsReady] = useState(false);
  let [outImgSrc, setOutImgSrc] = useState("");
  let [isInQueue, setIsInQueue] = useState(true);
  let [isToggled, setIsToggled] = useState(true);

  useEffect(() => {
    isAuth && setIsAuthRequired(false);
    isAuth && isSubmitted && !isReady && !isLoading && generate();
  }, [isAuth, isSubmitted, isLoading, isReady]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const apiName = process.env.REACT_APP_ENV_RUNPOD_API_NAME; // TODO: env variable
  const runEndpoint = async (requestPayload, userEmail) => {
    try {
      const restOperation = post({
        apiName: apiName,
        path: "/run",
        options: {
          body: {
            userEmail: userEmail,
            payload: requestPayload,
          },
        },
      });
      const { body } = await restOperation.response;
      return body.json();
    } catch (e) {
      console.error("POST call failed: ", e);
      alert(
        "Something wrong happened: " +
          e +
          "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
      );
      return -1;
    }
  };

  const checkStatus = async (requestId) => {
    try {
      const restOperation = post({
        apiName: apiName,
        path: "/status",
        options: {
          body: {
            id: requestId,
          },
        },
      });
      const { body } = await restOperation.response;
      return body.json();
    } catch (e) {
      console.error("POST call failed: ", e);
      alert(
        "Something wrong happened: " +
          e +
          "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
      );
      return -1;
    }
  };

  const cancelEndpoint = async (jobId) => {
    try {
      const restOperation = post({
        apiName: apiName,
        path: "/cancel",
        options: {
          body: {
            id: jobId,
          },
        },
      });
      const { body } = await restOperation.response;
      return body.json();
    } catch (e) {
      console.error("POST call failed: ", e);
      alert(
        "Something wrong happened: " +
          e +
          "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
      );
      return -1;
    }
  };

  const restart = () => {
    setIsReady(false);
    setIsLoading(false);
    setIsSubmitted(false);
    setOutImgSrc("");
  };

  const alertError = (message = "") => {
    if (message === "")
      alert("We're so sorry but something wrong happened ... Please try again");
    else alert(message);
    restart();
  };

  const float2int = (value) => {
    return value | 0;
  };

  // MODEL 1:
  // const power = 1;
  // const negPrompt = gender === "woman" ? negPromptWoman : negPromptMan;
  // const n_iter = 12 * power - 4 * (power - 1);
  // const n_nude = isNude === "nude" ? 1 : 1;
  // const payload = JSON.stringify({
  //   input: {
  //     init_image: inImgUrl,
  //     mask: maskUrl,
  //     api_name: "inpainting",
  //     prompt: [prompt2, prompt2, prompt2].slice(0, n_nude),
  //     negative_prompt: [
  //       negPrompt +
  //         "short, skirt, underwear, slip, cheeky, panty, thong, lingerie, shield",
  //       negPrompt +
  //         "short, skirt, underwear, slip, cheeky, panty, thong, lingerie",
  //       negPrompt + "short, skirt,",
  //     ].slice(0, n_nude),
  //     seed: [1, 1, 1].slice(0, n_nude),
  //     n_iter: [n_iter, 2, n_iter].slice(0, n_nude),
  //     denoising_strength: [
  //       0.55,
  //       0.55,
  //       0.65,
  //     ].slice(0, n_nude),
  //     // TODO: put a button to undress more powerfully
  //     steps: 55,
  //     cfg_scale: 20, // TODO: put a button to undress more powerfully
  //     sampler_name: "Euler a", //TODO: in list "Euler a", //
  //     num_images: 1,
  //     mask_blur: 20 * power - 4 * (power - 1),
  //     inpaint_full_res_padding: 128 * power - 32 * (power - 1),
  //     height: height, //TODO: adapt
  //     width: width,
  //     crop_coeff: 1, //TODO: in list
  //     rot_coeff: 0,
  //     do_gif: false,
  //     gif_duration: 200, //TODO: in list
  //     paramsStr: source + "_" + gender + "_" + isNude + "_" + isPhoto,
  //     email: userAttributes.email,
  //     model: "realisticVisionV60B1_v51HyperVAE.safetensors",
  //     controlNet: "control_v11p_sd15_openpose.pth",
  //   },
  // });

  const generate = async () => {
    const canvas = document.getElementById("canvas");
    
    if (canvas === null) {
      setIsSubmitted(false);
      alert("Please upload the image again");
      return;
    }
    const userAttributes = await fetchUserAttributes();
    const maskUrl = canvas.toDataURL("image/jpeg").split(",")[1];
    const inImgUrl = inImgSrc.split(",")[1];
    var img = document.getElementById("init_img");
    let width = float2int(img.naturalWidth);
    let height = float2int(img.naturalHeight);
    if (Math.max(width, height) < 500) {
      width = width * 2;
      height = height * 2;
    }

    const expertPrompt =
      document.getElementById("expertPrompt") !== null
        ? document.getElementById("expertPrompt").value
        : "";

    const breastPrompt =
      gender === "woman" && breastSize !== "auto"
        ? breastSize === "big"
          ? "(( very big boobs ))"
          : "(( very tiny tits ))"
        : "";
    const breastNegPrompt =
      gender === "woman" && breastSize !== "auto"
        ? breastSize === "small"
          ? "big boobs"
          : "small tits"
        : "";

    const bodyPrompt = bodySize !== "auto" ? "((" + bodySize + "))" : "";
    const bodyNegPrompt =
      bodySize !== "auto" ? (bodySize === "skinny" ? "fat" : "skinny") : "";

    const hairyPrompt = isHairy === "true" ? "((very hairy))," : "";
    const hairyNegPrompt = ""; //isHairy === "false" ? " ,((hairy))" : "";

    const agePrompt = age !== "auto" ? "((" + age + "))" : "";
    const ageNegPrompt =
      age !== "auto" ? (age === "young" ? "old" : "young") : "";

    const negPromptWomanOutfit =
      "(mutated hands and fingers:1.4), (deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, disconnected limbs, mutation, mutated, ugly, disgusting, amputation, deformed, blurry, weird, bad art, poorly drawn hands, bad hands, photoshop, doll, plastic_doll, silicone, anime, cartoon, fake, filter, airbrush, white clothes, clothes, cut image, extra fingers, bad feet, black clothes, blue clothes, pants, shirt, unrealistic vagina, artefacts, shield";
    const negPromptWomanNude =
      negPromptWomanOutfit +
      "short, skirt, underwear, slip, cheeky, panty, thong, lingerie";
    const negPromptMan =
      "pussy, woman, vagina, deformed, blurry, weird, bad art, poorly drawn hands, bad hands, photoshop, doll, plastic_doll, silicone, anime, cartoon, fake, filter, airbrush, white clothes, clothes, cut image, extra fingers, bad feet, black clothes, blue clothes, pants, shirt, 2 penises";

    const promptDict = {
      woman: {
        nude: {
          photo: {
            prompt:
              "photography of a completely nude " +
              bodyPrompt +
              agePrompt +
              " woman," +
              hairyPrompt +
              " uniform skin, fully naked, 4k, ultra detailed, natural pose, high resolution",
            negPrompt: negPromptWomanNude + hairyNegPrompt,
          },

          manga: {
            prompt:
              "animated hentai of a completely nude " +
              bodyPrompt +
              agePrompt +
              " woman, uniform skin, fully naked, hentai, anime, natural pose",
            negPrompt: negPromptWomanNude,
          },
        },
        underwear: {
          photo: {
            prompt:
              "photography of a " +
              bodyPrompt +
              agePrompt +
              " woman in " +
              outfit +
              ", uniform skin, 4k, ultra detailed, natural pose, high resolution",
            negPrompt: negPromptWomanOutfit,
          },
          manga: {
            prompt:
              "animated hentai of a " +
              bodyPrompt +
              agePrompt +
              " woman in " +
              outfit +
              ", uniform skin, fully naked, hentai, anime, natural pose",
            negPrompt: negPromptWomanOutfit,
          },
        },
      },
      man: {
        nude: {
          photo: {
            prompt:
              "photography of a " +
              bodyPrompt +
              agePrompt +
              " completely nude man," +
              hairyPrompt +
              " uniform skin, fully naked, 4k, ultra detailed, natural pose, high resolution",
            negPrompt: negPromptMan + hairyNegPrompt,
          },
          manga: {
            prompt:
              "animated hentai of a " +
              bodyPrompt +
              agePrompt +
              " completely nude man, uniform skin, fully naked, manga, anime, natural pose",
            negPrompt: negPromptMan,
          },
        },
        underwear: {
          photo: {
            prompt:
              "photography of a " +
              bodyPrompt +
              agePrompt +
              " man in " +
              outfit.replace("sexy ", "") +
              ", uniform skin, 4k, ultra detailed, natural pose, high resolution",
            negPrompt: negPromptMan,
          },
          manga: {
            prompt:
              "animated hentai of a " +
              bodyPrompt +
              agePrompt +
              " man in " +
              outfit +
              ", uniform skin, fully naked, manga, anime, natural pose",
            negPrompt: negPromptMan,
          },
        },
      },
    };
    const prompt =
      expertPrompt !== ""
        ? expertPrompt + "4k, ultra detailed, natural pose, high resolution"
        : promptDict[gender][isNude][isPhoto]["prompt"] + breastPrompt;
    const negPrompt =
      expertPrompt !== ""
        ? promptDict[gender]["underwear"][isPhoto]["negPrompt"]
        : promptDict[gender][isNude][isPhoto]["negPrompt"] +
          breastNegPrompt +
          "," +
          bodyNegPrompt +
          gender +
          "," +
          ageNegPrompt +
          gender;

    const p = power === "light" ? 0 : 1;

    const n_iter = 3 * p - 2 * (p - 1);
    const payload = JSON.stringify({
      input: {
        init_image: inImgUrl,
        mask: maskUrl,
        api_name: "inpainting",
        prompt: [prompt],
        negative_prompt: [negPrompt],
        seed: [-1],
        n_iter: [n_iter],
        denoising_strength: [0.9 * p - 0.75 * (p - 1)],
        // TODO: put a button to undress more powerfully
        steps: 50,
        cfg_scale: 30 * p - 20 * (p - 1), // TODO: put a button to undress more powerfully
        sampler_name: "Euler a", //TODO: in list "Euler a", //
        num_images: 1,
        mask_blur: 32 * p - 20 * (p - 1),
        inpaint_full_res_padding: 256 * p - 128 * (p - 1),
        height: height, //TODO: adapt
        width: width,
        crop_coeff: 1, //TODO: in list
        rot_coeff: 0,
        do_gif: false,
        gif_duration: 200, //TODO: in list
        paramsStr:
          source + "_" + gender + "_" + isNude + "_" + isPhoto + "_" + power,
        email: userAttributes.email,
        model: "realisticVisionV60B1_v51HyperInpaintVAE.safetensors", //"realisticVisionV60B1_v51HyperVAE.safetensors"
        controlNet: "",
      },
    });
    // const payload_txt2img = JSON.stringify({ //TODO: generate your model instead of choosing image (it's working good)
    //   input: {
    //     api_name: "txt2img",
    //     prompt:
    //       "hyperrealistic photography of a woman, full body, light clothes on, taken from face, 4k, ultra detailed, natural pose, high resolution",
    //     negative_prompt:
    //       "deformed, weird, weird face, weird eyes, dark clothes, black clothes, anime, cartoon, plastic doll, poor art, underwear",
    //     seed: -1,
    //     // TODO: put a button to undress more powerfully
    //     steps: 40,
    //     cfg_scale: 8, // TODO: put a button to undress more powerfully
    //     sampler_name: "DPM++ 2M", //TODO: in list "Euler a", //
    //     num_images: 1,
    //     mask_blur: 4,
    //     inpaint_full_res_padding: 32,
    //     height: height, //TODO: adapt
    //     width: width,
    //     crop_coeff: 1, //TODO: in list
    //     rot_coeff: 0,
    //     do_gif: false,
    //     gif_duration: 200, //TODO: in list
    //   },
    // });

    setIsLoading(true);
    try {
      const response = await runEndpoint(payload, userAttributes.email);
      if (response.status !== 200) {
        setIsSubmitted(false);
        alertError(response.message);
        if (response.status === 444) onNoMoreTry();
        return;
      }

      const { id } = response.data; // Extracting the operation ID from the initial run response
      if (!id) {
        console.log("No ID");
        alertError();
        return;
      }
      // Poll the status of the operation until it completes or fails

      let isComplete = false;
      while (!isComplete) {
        const checkStat = await checkStatus(id);
        const statusResponse = checkStat.data;
        if (statusResponse.status === "IN_QUEUE") {
          setIsInQueue(true);
          await sleep(3000);
        } else if (statusResponse.status === "IN_PROGRESS") {
          setIsInQueue(false);
          await sleep(3000); // Wait for a bit before checking the status again
        } else if (
          statusResponse.status === "COMPLETED" ||
          statusResponse.status === "FAILED"
        ) {
          setIsReady(true); // Exit the loop
          setIsLoading(false);
          setIsSubmitted(false);
          setOutImgSrc("");
          // Once completed or failed, log the final status and break the loop
          if (statusResponse.status === "COMPLETED" && statusResponse.output) {
            setOutImgSrc(statusResponse.output["url"][0]);
            // setOutImgSrc(statusResponse.output["url"][n_nude * n_iter - 1]);
          } else {
            console.log("status failed");
            alertError();
            break;
          }
          isComplete = true;
        }
      }
    } catch (e) {
      console.error(e);
      alertError();
    }
  };

  const handleSubmit = () => {
    if (isSubmitted) {
      return;
    }
    if (!isLoaded) {
      alert("Please upload an image first!");
      return;
    }
    if (!hasDrawn) {
      alert("Please draw on image what clothes to remove");
      return;
    }
    // setIsToggled(true);
    setIsSubmitted(true);
    setIsReady(false);
    setIsLoading(false);
    setIsInQueue(true);
    if (isAuth) {
      setIsAuthRequired(false);
    } else {
      setIsAuthRequired(true);
    }
  };

  const authComponent = (
    <>
      <h2>Please log in to undress</h2>
      <Authenticator></Authenticator>
    </>
  );
  const loading = <LoadingComponent isInQueue={isInQueue}></LoadingComponent>;
  const changeDress = () => {
    setIsToggled(!isToggled);
  };

  const result = (
    <>
      <h2>Result: {isToggled ? "(Processed Image)" : "(Original Image)"} </h2>
      <button
        onClick={changeDress}
        className={`toggle-button ${isToggled ? "off" : "on"}`}
      >
        {isToggled ? "See dressed up" : "See undressed"}
      </button>
      <br />
      <br />
      <div id="output">
        <a href={isToggled ? outImgSrc : inImgSrc} target="_blank">
          <img src={isToggled ? outImgSrc : inImgSrc} alt="output" />
        </a>

        {/* <input
          id="post"
          className="btn-fill"
          type="submit"
          value="Post on our Collection"
          onClick={() => postDeepNude()}
        /> */}
        {/* {outImgSrc.map((value, index) => (
          <a key={index} href={value} target="_blank">
            <img key={index} src={value} alt="output" />
          </a>
        ))} */}
      </div>
    </>
  );

  const postDeepNude = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      alert("Only available in Pro Plan");
      const restOperation = post({
        apiName: "undressStripeApi",
        path: "/cancelsub",
        options: {
          body: {
            userEmail: userAttributes.email,
            plan: "Post",
          },
        },
      });
      const { body } = await restOperation.response;
    } catch (e) {
      console.error("POST call failed: ", e);
      alert(
        "Something wrong happened: " +
          e +
          "\nIf the problem persists, please contact support@ailovenudes.com sending the mentioned error."
      );
    }
  };
  return (
    <section className="generate" id="generate">
      {/* TODO change section id ?  */}
      <div className="container">
        <h1 className="step">
          <br /> <u>Step 4: Just click 😉</u>
          <br />
        </h1>
        <a href="#undress">
          <input
            id="undress"
            className="btn-fill"
            type="submit"
            value="Undress"
            onClick={handleSubmit}
          />
        </a>
        <br />
        <br />
        <br />
        <br />
        <div className="result" id="result">
          {isSubmitted &&
            ((isAuthRequired && authComponent) ||
              (isAuth && isLoading && loading))}
          {!isSubmitted && isAuth && isReady && !isLoading && result}

          <label>
            <br />
            <br />
            If some clothes remain, or the result is unexpected, try to use
            "Strong" option in Standard parameters.
            <br />
            <br />
            <br />
            The better the original photo, the better the result ! (high
            quality, facing camera, body covering most of the image ...)
            <br />
            <br />
            <br />
            <br />
          </label>
        </div>
      </div>
    </section>
  );
};

export default ResultPage;
